var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"testing__item block__content"},[_c('div',{staticClass:"testing__item-title mb-3"},[_vm._v(" "+_vm._s(_vm.question.Num)+". "+_vm._s(_vm.question.Text)+" ")]),_c('div',{staticClass:"testing__item-answers"},[(_vm.multiple)?_vm._l((_vm.question.Variants),function(item,index){return _c('v-checkbox',{key:index,attrs:{"color":item.IsCorrect === null
            ? ''
            : item.IsCorrect
            ? 'success lighten-1'
            : 'error lighten-1',"label":item.Text,"value":index,"readonly":_vm.isDone,"hide-details":""},model:{value:(_vm.radioGroup),callback:function ($$v) {_vm.radioGroup=$$v},expression:"radioGroup"}})}):_c('v-radio-group',{model:{value:(_vm.radioGroup),callback:function ($$v) {_vm.radioGroup=$$v},expression:"radioGroup"}},_vm._l((_vm.question.Variants),function(item,index){return _c('v-radio',{key:index,attrs:{"color":item.IsCorrect === null
            ? ''
            : item.IsCorrect
            ? 'success lighten-1'
            : 'red lighten-1',"label":item.Text,"value":index,"readonly":_vm.isDone}})}),1),(_vm.multiple)?_c('div',{staticClass:"caption mt-2"},[_vm._v(" * Допустимо два или более правильных вариантов ответа ")]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }