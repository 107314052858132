<template>
  <div class="testing__item block__content">
    <div class="testing__item-title mb-3">
      {{ question.Num }}. {{ question.Text }}
    </div>
    <div class="testing__item-answers">
      <template v-if="multiple">
        <v-checkbox
          v-model="radioGroup"
          v-for="(item, index) in question.Variants"
          :key="index"
          :color="
            item.IsCorrect === null
              ? ''
              : item.IsCorrect
              ? 'success lighten-1'
              : 'error lighten-1'
          "
          :label="item.Text"
          :value="index"
          :readonly="isDone"
          hide-details=""
        ></v-checkbox>
      </template>

      <v-radio-group v-else v-model="radioGroup">
        <v-radio
          v-for="(item, index) in question.Variants"
          :key="index"
          :color="
            item.IsCorrect === null
              ? ''
              : item.IsCorrect
              ? 'success lighten-1'
              : 'red lighten-1'
          "
          :label="item.Text"
          :value="index"
          :readonly="isDone"
        ></v-radio>
      </v-radio-group>
      <div class="caption mt-2" v-if="multiple">
        * Допустимо два или более правильных вариантов ответа
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    question: {
      type: Object,
      default: () => {},
    },
    isDone: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      radioGroup:
        this.question.Type === 2
          ? this.question.Variants.filter((e) => e.IsSelected).map((e) =>
              this.question.Variants.findIndex((e2) => e2.Id === e.Id)
            )
          : this.question.Variants.findIndex((e) => e.IsSelected),
    };
  },
  computed: {
    multiple() {
      return this.question.Type === 2;
    },
  },
  watch: {
    radioGroup(val) {
      for (let i = 0; i < this.question.Variants.length; i++) {
        this.question.Variants[i].IsSelected = val.length
          ? val.includes(i)
          : val === i;
      }
    },
    "question.Variants": {
      handler() {
        this.radioGroup = null;
        if (this.multiple) {
          this.radioGroup = [];
          for (let i = 0; i < this.question.Variants.length; i++) {
            if (this.question.Variants[i].IsSelected) this.radioGroup.push(i);
          }
        } else {
          this.radioGroup = this.question.Variants.findIndex(
            (e) => e.IsSelected
          );
        }
      },
    },
  },
  methods: {
    select(item) {
      console.log("item", item);
    },
  },
};
</script>
<style lang="scss">
.testing {
  &__item {
    padding: 20px 31px;
    &-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: var(--v-text-base);
    }
    &-answers {
      .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
        margin-bottom: 16px;
      }
      .v-label {
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: var(--v-text-base);
      }
    }
  }
  &__item:not(:last-child) {
    margin-bottom: 15px;
  }
}
</style>
